import React from 'react'
import Layout from '../components/layout'
import { Container, Row, Col } from 'react-bootstrap'


const PageNotFound = () => {
    return(
        <Layout>
            <Container className="mt-5 mb-5">
                <Row>
                    <Col>
                        <h1>Page not found</h1>
                        <p>Die angeforderte Seite wurde nicht gefunden!</p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default PageNotFound
